<template>
  <v-layout wrap v-if="editedItem" class="form-menu">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-menu</v-icon>
      <v-toolbar-title>
        {{ $t(`common.${isClone ? 'clone' : isNew ? 'create' : 'edit'}`, locale) }} {{ $t('dtouch.menu', locale).toLowerCase() }}
      </v-toolbar-title>
    </v-toolbar>
    <v-flex xs12 style="padding:20px">
      <translate
        v-model="editedItem.Name"
        :languages="languages"
        :label="$t('folders.name', locale)"
        @input="handleChangeIsModified"
        type="text"
      />
    </v-flex>
    <v-flex xs12 style="padding:20px">
      <select-image 
        :image="editedItem.Image"
        :onChange="handleChangeImage"
        :locale="locale"
        :folder="workspaceID"
      />
    </v-flex>
    <v-flex 
      v-if="!isNew"
      xs12 
      style="padding:0 20px 20px 20px;"
    >
      <v-row style="padding-bottom: 10px;">
        <v-col xs="5" style="padding-bottom:0">
          <v-divider style="margin-top: 12px;"/>
        </v-col>
        <v-col xs="2" style="padding-bottom:0; text-align:center;">
            <b>{{ $t('common.or', locale) }}</b>
        </v-col>
        <v-col xs="5" style="padding-bottom:0">
          <v-divider style="margin-top: 12px;"/>
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        block
        @click="onAddContent({ isMenuWithSubmenus: editedItem.Type === 1 ? true : false, id: editedItem.ID})"
        style="margin-top:20px;"
      >
        {{ $t('dtouch.addContent', locale) }}
      </v-btn>
    </v-flex>
    <!--v-flex xs12 style="padding: 0 0 10px 10px">
      <v-row>
        <v-col>
          <div style="float: left;">
            <label class="dg-label" style="margin-right: 10px;">
              {{ $t('dtouch.menu', locale) }}
            </label>
          </div>
          <div style="float: left;">
            <color-picker-primary-secondary
              :primaryColor="editedItem.BGColor"
              :secondaryColor="editedItem.TextColor"
              :onChangeColor="handleChangeColorMenu"
              :iconBGAndText="true"
            />
          </div>
        </v-col>
        <v-col>
          <div style="float: left;">
            <label class="dg-label" style="margin-right: 10px;">
              {{ $t('dtouch.content', locale) }}
            </label>
          </div>
          <div style="float: left;">
            <color-picker-primary-secondary
              :primaryColor="editedItem.BGColorContent"
              :secondaryColor="editedItem.TextColorContent"
              :onChangeColor="handleChangeColorContent"
              :iconBGAndText="true"
            />
          </div>
        </v-col>
      </v-row>
    </v-flex-->
    <v-flex xs12 class="common-forms">
      <v-expansion-panels focusable>
        <insert-between-panel
          v-if="isNew || isClone"
          :locale="locale"
          :parentID="newParentID"
          :isMenu="true"
          :onChange="handleChangeInsertBetween"
        />
        <move-panel
          v-if="!isNew && !isClone"
          :locale="locale"
          :parentID="newParentID"
          :editedID="editedItem.ID"
          :isMenuWithSubmenus="editedItem.Type === 1"
          :onChangeMenu="handleChangeMoveToMenu"
          :onChangeWorkspace="handleChangeMoveToWorkspace"
          :isSubmenu="editedItem.ParentID !== null"
        />
        <customize
          :locale="locale"
          :editedItem="editedItem"
          :onChangeColorMenu="handleChangeColorMenu"
          :onChangeColorContent="handleChangeColorContent"
        />
        <availability-panel
          :availability="editedItem.Availability"
          :locale="locale"
          :onChange="handleChangeAvailability"
        />
      </v-expansion-panels>
    </v-flex>
    <v-flex xs12>
      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
        :isClone="isClone"
      />
    </v-flex>
    <!--v-flex xs12 class="availability">
      <availability
        v-model="editedItem.Availability"
        :locale="locale"
        @change="handleChangeAvailability"
      />
    </v-flex-->
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import { defaultMenu } from '@/constants/dtouch'
import SelectImage from '@/components/selectImage/Index'
import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'
//import ColorPickerPrimarySecondary from '@/components/colorPickerPrimarySecondary/Index'
import AvailabilityPanel from '@/components/AvailabilityPanel'
import InsertBetweenPanel from '@/components/InsertBetweenPanel'
import MovePanel from '@/components/MovePanel'
import Customize from './Customize'
export default {
  name: 'folder-form',
  components: {
    Translate,
    ActionButtons,
    //ColorPickerPrimarySecondary,
    AvailabilityPanel,
    InsertBetweenPanel,
    MovePanel,
    Customize,
    SelectImage,
  },
  props: {
    editedID: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true
    },
    newParentID: {
      // used only when creating a new menu
      type: String,
      default: null,
    },
    isClone: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      required: true,
    },
    onAddContent: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      editedItem: null,
      showSelectCustomer: false,
    }
  },
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.config.Languages
    },
    isNew () {
      return this.editedID === 'new'
    },
    user () {
      return utils.getUser()
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedID (v) {
      if (v) this.handleGetItem()
    },
  },
  mounted () {
    if (this.editedID) this.handleGetItem()
  },
  methods: {
    handleGetItem () {
      if (this.isNew) {
        this.editedItem = JSON.parse(JSON.stringify(defaultMenu))
        this.editedItem.ParentID = this.newParentID
        this.editedItem.EnterpriseID = utils.getWorkspace('dtouch')
      } else 
        api.getItem ('dtouch', 'v1/private/menus/', this.editedID)
          .then(response => {
            response.Name = JSON.parse(response.Name)
            response.Availability = JSON.parse(response.Availability)
            this.editedItem = response
          })
    },
    handleChangeImage (v) {
      if (v) {
        this.editedItem.Image = v.base64
        this.editedItem.newImage = v
        this.handleChangeIsModified()
      }
    },
    handleChangeIsModified () {
      this.$store.state.app.dtouchMenuEdit = {
        ...this.editedItem,
        LMD: new Date().getTime()
      }
    },
    handleChangeColorMenu (bg, text) {
      this.editedItem.BGColor = bg
      this.editedItem.TextColor = text
      this.handleChangeIsModified()
    },
    handleChangeColorContent (bg, text) {
      this.editedItem.BGColorContent = bg
      this.editedItem.TextColorContent = text
      this.handleChangeIsModified()
    },
    handleChangeVisibility (v) {
      this.editedItem.Visibility = v
      this.showSelectCustomer = this.editedItem.Visibility === 'privateByUser'
    },
    handleChangeAvailability (v) {
      this.editedItem.Availability = v
    },
    handleSave () {
      if (this.isClone)
        api.updateItem ('dtouch', 'v1/private/menus/', this.editedItem.ID.concat('/clone'), this.editedItem)
          .then((response) => {
            response.data.isMenu = true
            if (response.data.insertBetween) this.onClose('fullRefresh', response.data.ParentID)
            else this.onClose('addContent', response.data)
          })
      else if (this.isNew)
        api.addItem ('dtouch', 'v1/private/menus/', this.editedItem)
          .then((response) => {
            response.data.isMenu = true
            if (response.data.insertBetween) this.onClose('fullRefresh', response.data.ParentID)
            else this.onClose('addContent', response.data)
          })
      else 
        api.updateItem ('dtouch', 'v1/private/menus/', this.editedItem.ID, this.editedItem)
          .then((response) => {
            const id = response.data && response.data.ID ? response.data.ID : null
            // if moveTo remove id refresh moveToID
            this.onClose(this.editedItem.moveTo ? 'moveMenu' : 'updateContent', id)
          })
    },
    handleDelete () {
      api.deleteItem('dtouch', `v1/private/menus/`, this.editedID)
        .then(response => {
          if(response && response.data.affectedRows > 0) this.onClose('deleteContent', this.editedID)
        })
    },
    handleChangeMoveToMenu (id, newParentID) {
      this.editedItem.moveTo = newParentID ? {
        type: 'menu',
        menuID: newParentID
      } : null
    },
    handleChangeMoveToWorkspace (menuID, workspaceID) {
      this.editedItem.moveTo = workspaceID ? {
        type: 'workspace',
        workspaceID: workspaceID
      } : null
    },
    handleChangeInsertBetween (v) {
      this.editedItem.insertBetween = v
    },
  }
}
</script>
<style scoped>
.form-menu .availability {
  background-color: #f3f0f0;
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  margin-top: 15px;
}
.form-menu .common-forms {
  padding: 0 10px 10px 10px;
}
</style>

