<template>
  <v-expansion-panel
    @click="handleExpand"
  >
    <v-expansion-panel-header>
      {{ $t('dtouch.movePanel.title', locale) }}
      <v-spacer />
    </v-expansion-panel-header>
    <v-expansion-panel-content 
      style="margin-top: 10px;"
    >
      <v-progress-linear
        indeterminate
        v-if="loading"
        color="primary"
      />
      <v-btn
        v-if="isSubmenu && !isMenuWithSubmenus"
        @click="handleMoveToRoot"
        color="primary"
        block
      >
        {{ $t('dtouch.movePanel.moveToRoot', locale) }}
      </v-btn>
      <v-autocomplete
        v-if="menus && !isSubmenu && !selectedWorkspace && !isMenuWithSubmenus"
        v-model="selectedMenu"
        :disabled="loading"
        :items="menus"
        filled
        chips
        color="blue-grey lighten-2"
        item-text="name"
        item-value="id"
        hide-details
        @change="handleChange"
        style="padding: 10px 0;"
        :label="$t('dtouch.movePanel.menus', locale)"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="handleRemove"
          >
            <v-avatar left>
              <v-icon>{{ data.item.icon }}</v-icon>
            </v-avatar>
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar>
              <v-icon>{{ data.item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <!--v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle-->
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="workspaces && !selectedMenu && !isSubmenu"
        v-model="selectedWorkspace"
        :disabled="loading"
        :items="workspaces"
        filled
        chips
        color="blue-grey lighten-2"
        item-text="name"
        item-value="id"
        hide-details
        @change="handleChangeWorkspace"
        style="padding: 10px 0;"
        :label="$t('dtouch.movePanel.workspaces', locale)"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="handleRemove"
          >
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <!--v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle-->
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  name: 'InsertIntoBetweenPanel',
  props: {
    locale: {
      type: String,
      required: true
    },
    editedID: {
      type: String,
      required: true,
    },
    parentID: {
      type: String,
      default: null,
    },
    onChangeMenu: {
      type: Function,
      required: true,
    },
    onChangeWorkspace: {
      type: Function,
      required: true,
    },
    isMenuWithSubmenus: {
      type: Boolean,
      default: false,
    },
    isSubmenu: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menus: null,
    workspaces: null,
    selectedMenu: null,
    selectedWorkspace: null,
    loading: false
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  methods: {
    getData: async function () {
      this.loading = true
      if (!this.isSubmenu) {
        const url =  `v1/private/workspaces/${this.workspaceID}/menus/${this.parentID}/`
        this.menus = await new Promise((resolve, reject) => api.getAll ('dtouch', url, 0, 10000)
          .then(response => {
            resolve(response.data.filter(x => x.Type === 1).map(item => {
                return {
                  id: item.ID,
                  name: utils.getTranslation(item.Name, this.locale),
                  icon: 'mdi-menu',
                  ord: item.ORD,
                }
            }))
          })
          .catch(error => {
            console.log(error)
            reject(error)
          }))
      }
      this.workspaces = await new Promise ((resolve, reject) => 
        api.getAllWithoutLimit ('dtouch', `v1/internal/users/${utils.getUser()}/workspaces`)
          .then(response => {
            resolve(response.map(item => {
              return {
                id: item.ID,
                name: item.Name,
              }
            }))
          })
        .catch(error => reject(error))
      )
      this.loading = false
    },
    handleExpand () {
      if (!this.menus && !this.workspaces) this.getData()
    },
    handleMoveToRoot () {
      this.onChangeMenu(this.editedID, 'root')
    },
    handleChange () {
      const aux = this.menus.filter(x => x.id === this.selectedMenu).shift()
      if (aux) this.onChangeMenu(this.editedID, aux.id)
    },
    handleRemove () {
      this.selectedMenu = null
      this.selectedWorkspace = null
      this.onChangeMenu(this.editedID, null)
      this.onChangeWorkspace(this.editedID, null)
    },
    handleChangeWorkspace () {
      this.onChangeWorkspace(this.editedID, this.selectedWorkspace)
    },
  },
}
</script>

